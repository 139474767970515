import React from 'react';

const VIRUS_ICON =
  'M 483.55 227.55 H 462 c -50.68 0 -76.07 -61.27 -40.23 -97.11 L 437 115.19 A 28.44 28.44 0 0 0 396.8 75 L 381.56 90.22 c -35.84 35.83 -97.11 10.45 -97.11 -40.23 V 28.44 a 28.45 28.45 0 0 0 -56.9 0 V 50 c 0 50.68 -61.27 76.06 -97.11 40.23 L 115.2 75 A 28.44 28.44 0 0 0 75 115.19 l 15.25 15.25 c 35.84 35.84 10.45 97.11 -40.23 97.11 H 28.45 a 28.45 28.45 0 1 0 0 56.89 H 50 c 50.68 0 76.07 61.28 40.23 97.12 L 75 396.8 A 28.45 28.45 0 0 0 115.2 437 l 15.24 -15.25 c 35.84 -35.84 97.11 -10.45 97.11 40.23 v 21.54 a 28.45 28.45 0 0 0 56.9 0 V 462 c 0 -50.68 61.27 -76.07 97.11 -40.23 L 396.8 437 A 28.45 28.45 0 0 0 437 396.8 l -15.25 -15.24 c -35.84 -35.84 -10.45 -97.12 40.23 -97.12 h 21.54 a 28.45 28.45 0 1 0 0 -56.89 Z M 224 272 a 48 48 0 1 1 48 -48 A 48 48 0 0 1 224 272 Z m 80 56 a 24 24 0 1 1 24 -24 A 24 24 0 0 1 304 328 Z';

const YES_ICON =
    'M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z',
  NO_ICON =
    'M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z';

const DRUG_ICON =
  'M 555.3 300.1 L 424.2 112.8 C 401.9 81 366.4 64 330.4 64 c -22.6 0 -45.5 6.7 -65.5 20.7 c -19.7 13.8 -33.7 32.8 -41.5 53.8 C 220.5 79.2 172 32 112 32 C 50.1 32 0 82.1 0 144 v 224 c 0 61.9 50.1 112 112 112 s 112 -50.1 112 -112 V 218.9 c 3.3 8.6 7.3 17.1 12.8 25 L 368 431.2 c 22.2 31.8 57.7 48.8 93.8 48.8 c 22.7 0 45.5 -6.7 65.5 -20.7 c 51.7 -36.2 64.2 -107.5 28 -159.2 Z M 160 256 H 64 V 144 c 0 -26.5 21.5 -48 48 -48 s 48 21.5 48 48 v 112 Z m 194.8 44.9 l -65.6 -93.7 c -7.7 -11 -10.7 -24.4 -8.3 -37.6 c 2.3 -13.2 9.7 -24.8 20.7 -32.5 c 8.5 -6 18.5 -9.1 28.8 -9.1 c 16.5 0 31.9 8 41.3 21.5 l 65.6 93.7 l -82.5 57.7 Z';

const EDIT_ICON =
  'M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z';

const SEARCH_ICON =
  'M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z';

const LOADING_ICON = (
  <g>
    <text textAnchor="middle" x="24">
      LOADING...
    </text>
    <rect x="0" y="50" width="14" height="30" fill="#1890ff">
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="translate"
        values="0 0; 0 20; 0 0"
        begin="0"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="20" y="50" width="14" height="30" fill="#1890ff">
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.2s"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </rect>
    <rect x="40" y="50" width="14" height="30" fill="#1890ff">
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.4s"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </rect>
  </g>
);

export {
  VIRUS_ICON,
  YES_ICON,
  NO_ICON,
  DRUG_ICON,
  EDIT_ICON,
  LOADING_ICON,
  SEARCH_ICON,
};
